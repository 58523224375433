import * as firestore from 'firebase/firestore';
import * as realtime from 'firebase/database';

import { getDoc, query, where } from 'firebase/firestore';
import { db, realtimeDatabase, defaultAuth } from './firebase';
import { useEffect, useState } from 'react';
import { generatePassword } from './guess-utils';

export const ROOMS_COLLECTION = 'rooms';

export const createRoom = async (name, passwordLength, isPublic) => {
    const user = defaultAuth.currentUser;
    const rooms = firestore.collection(db, ROOMS_COLLECTION);
    const ref = await firestore.addDoc(rooms, {
        roomName: name,
        adminUid: user.uid,
        createdTimestamp: firestore.serverTimestamp(),
        gameStartTimestamp: null,
        gameEndTimestamp: null,
        isPublic,
    });

    const roomsRealtimeCollection = realtime.ref(realtimeDatabase, 'rooms');
    const realtimeRef = await realtime.push(roomsRealtimeCollection);
    await realtime.set(realtimeRef, {
        roomId: ref.id,
        players: [],
        spectatorsCount: 0,
        roundIndex: 0,
        password: generatePassword(passwordLength),
    });

    await firestore.setDoc(ref, {realtimeItemId: realtimeRef.key}, {merge: true});

    return ref.id;
}

export const listRooms = async () => {
    const rooms = firestore.collection(db, ROOMS_COLLECTION);
    const q = query(rooms, 
        where('isPublic', '==', true),
        where('gameStartTimestamp', '==', null),
        where('gameEndTimestamp', '==', null));
    const snapshot = await firestore.getDocs(q);
    return snapshot.docs.map(doc => [doc.id, doc.data()])
}

export const describeRoom = async (roomId) => {
    const ref = firestore.doc(db, ROOMS_COLLECTION, roomId);
    const snapshot = await getDoc(ref);
    return snapshot.data();
}

export const addPlayerToRoom = async (roomId, playerId) => {
    const room = await describeRoom(roomId);
    const playersListRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/players`);
    const players = (await realtime.get(playersListRef)).val();
    if (players && Object.values(players).find(entry => entry.playerId === playerId)) {
        return;
    }

    await realtime.push(playersListRef, {playerId, score: 0});
};

export const removePlayerFromRoom = async (roomId, playerId) => {
    const room = await describeRoom(roomId);
    const playersListRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/players`);
    const players = (await realtime.get(playersListRef)).val();
    if (!players) {
        return;
    }

    const ref = Object.entries(players).find(([key, entry]) => entry.playerId === playerId);
    if (!ref) {
        console.debug(`Player ${playerId} is not in room ${roomId}.`);
        return;
    }

    await realtime.remove(`/rooms/${room.realtimeItemId}/players/${ref[0]}`);
}

export const markScoreAndAdvanceToNextRound = async (roomId, playerId, scoreDelta) => {
    const room = await describeRoom(roomId);
    const playersListRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/players`);
    const players = (await realtime.get(playersListRef)).val();
    
    if (!players) {
        throw new Error('Players must exist in the room.');
    }

    const [playerKey] = Object.entries(players).find(([, entry]) => entry.playerId === playerId);
    if (!playerKey) {
        throw new Error(`Player ${playerId} is not in room ${roomId}.`);
    }

    const playerRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/players/${playerKey}/score`);
    await realtime.set(playerRef, realtime.increment(scoreDelta));

    const roundIndexRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/roundIndex`);
    await realtime.set(roundIndexRef, realtime.increment(1));
};

export const startGame = async (roomId) => {
    const ref = firestore.doc(db, ROOMS_COLLECTION, roomId);
    await firestore.setDoc(ref, {gameStartTimestamp: firestore.serverTimestamp()}, {merge: true});

    const room = await describeRoom(roomId);
    const timestampRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/gameStartTimestamp`);
    await realtime.set(timestampRef, room.gameStartTimestamp);
};

export const completeGame = async (roomId) => {
    const ref = firestore.doc(db, ROOMS_COLLECTION, roomId);
    await firestore.setDoc(ref, {gameEndTimestamp: firestore.serverTimestamp()}, {merge: true});

    const room = await describeRoom(roomId);
    const timestampRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}/gameEndTimestamp`);
    await realtime.set(timestampRef, room.gameStartTimestamp);
};

export const deleteRoom = async (roomId) => {
    const room = await describeRoom(roomId);

    const metaRef = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}`);
    await realtime.remove(metaRef);

    const roomRef = firestore.doc(db, ROOMS_COLLECTION, roomId);
    await firestore.deleteDoc(roomRef);
}

export const useRoom = (roomId) => {
    const [isLoading, setLoading] = useState(true);
    const [room, setRoom] = useState(undefined);
    const [realtimeData, setRealtimeData] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        describeRoom(roomId)
            .then(setRoom)
            .catch(console.log);
    }, [roomId]);

    useEffect(() => {
        if (room === undefined) {
            return;
        }

        const ref = realtime.ref(realtimeDatabase, `/rooms/${room.realtimeItemId}`);
        return realtime.onValue(ref, data => {
            setRealtimeData(data.val());
            setLoading(false);
        });
    }, [room]);

    return [room, realtimeData, isLoading];
};
