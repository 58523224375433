import { AppLayout, Box, Button, Container, Header, Modal, SpaceBetween, TextContent } from "@awsui/components-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { defaultAuth, describeUser } from "./firebase";
import { addPlayerToRoom, completeGame, deleteRoom, markScoreAndAdvanceToNextRound, startGame, useRoom } from "./rooms_api";
import cx from 'classnames';
import { hint, isDigit } from "./guess-utils";
import { useCallback, useLayoutEffect } from "react";

import './room.css';

export function GameRoom(props) {
    const { roomId } = useParams();
    const [ user, isAuthLoading ] = useAuthState(defaultAuth);
    const [ room, realtimeData, isRoomLoading ] = useRoom(roomId);
    const [ deleteModalActive, setDeleteModalActive] = useState(false);
    const [ isDeletingRoom, setDeletingRoom ] = useState(false);

    const navigateTo = useNavigate();

    useEffect(() => {
        if (user === null) {
            return;
        }

        addPlayerToRoom(roomId, user.uid);
    }, [user, roomId]);

    useEffect(() => {
        if (isRoomLoading || !realtimeData || isDeletingRoom) {
            return;
        }

        if (realtimeData.roundIndex < realtimeData.password.length) {
            return;
        }

        completeGame(roomId).finally(() => navigateTo(`/rooms/${roomId}/stats`));
    }, [roomId, room, realtimeData, isRoomLoading, isDeletingRoom, navigateTo]);

    const roundCompleteHandler = useCallback(({score}) => {
        if (!user) {
            return;
        }

        markScoreAndAdvanceToNextRound(roomId, user.uid, score);
    }, [roomId, user]);

    const startGameHandler = useCallback(() => {
        startGame(roomId).catch(console.log);
    }, [roomId]);

    const deleteRoomHandler = useCallback(() => {
        setDeletingRoom(true);
        deleteRoom(roomId)
            .then(() => navigateTo('/'))
            .catch(console.log);
    }, [roomId, navigateTo]);

    const isMyTurn = useMemo(() => {
        if (!realtimeData) {
            if (!isRoomLoading) {
                console.warn('Room metadata not available.');
            }
            return false;
        }

        const activePlayersList = Object.values(realtimeData.players);
        if (ActivePlayersList.length === 0) {
            console.warn('There are no players in the room.');
            return false;
        }

        const activePlayer = activePlayersList[realtimeData.roundIndex % activePlayersList.length];
        return activePlayer.playerId === user.uid;
    }, [realtimeData, user, isRoomLoading]);

    const isAdmin = useMemo(() => {
        if (!user || !room) {
            return false;
        }

        return user.uid === room.adminUid;
    }, [user, room]);

    if (!isAuthLoading && !user) {
        navigateTo('/');
    }

    if (isAuthLoading || isRoomLoading || isDeletingRoom) {
        return (
            <div>Loading...</div>
        );
    }

    const isGameRunning = realtimeData && !!realtimeData.gameStartTimestamp;

    const contentHeader = (
        <Header variant="h1" description={<>Room ID: <strong>{roomId}</strong></>}>{room.roomName}</Header>
    );
    const content = (
        <SpaceBetween size="s">
            {isAdmin && <Container>
                <SpaceBetween size="xs" direction="horizontal">
                    <Button onClick={startGameHandler} disabled={isGameRunning} variant="primary">
                        Start Game
                    </Button>
                    <Button onClick={() => setDeleteModalActive(true)} variant="normal">
                        Delete Room
                    </Button>
                </SpaceBetween>
            </Container>}
            <SpaceBetween direction="horizontal" size="s">
                <Container>
                    <PasswordGuessInput
                        expectedPassword={realtimeData.password}
                        roundIndex={realtimeData.roundIndex}
                        isMyTurn={isMyTurn}
                        onRoundComplete={roundCompleteHandler}
                        disabled={!isGameRunning}
                    />
                </Container>
                <Container 
                    header={<Header variant="h2">Players</Header>}
                    footer={<TextContent>
                        <strong>Spectators:</strong> {realtimeData.spectatorsCount}
                    </TextContent>}
                >
                    <ActivePlayersList
                        activePlayers={realtimeData.players}
                        roundIndex={realtimeData.roundIndex}
                    />
                </Container>
            </SpaceBetween>
        </SpaceBetween>
    );

    return (
        <>
            {!isDeletingRoom && <AppLayout
                headerSelector="#topNavigation"
                content={content}
                contentHeader={contentHeader}
                maxContentWidth={800}
                navigationHide
                toolsHide
            />}
            <Modal
                size="small"
                visible={deleteModalActive}
                onDismiss={() => setDeleteModalActive(false)}
                footer={
                    <Box float="right">
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button variant="link" onClick={() => setDeleteModalActive(false)}>Cancel</Button>
                            <Button variant="primary" onClick={deleteRoomHandler} loading={isDeletingRoom}>Confirm</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Delete Room"
            >
                Are you sure you want to delete game room <strong>{room.roomName}</strong>?
            </Modal>
        </>
    );
}

export const PasswordGuessInput = ({expectedPassword, roundIndex, isMyTurn, onRoundComplete, disabled}) => {
    const MAX_ATTEMPTS = 10;

    const [ remainingAttempts, setRemainingAttempts ] = useState(MAX_ATTEMPTS);

    const onHit = useCallback(() => {
        onRoundComplete({score: remainingAttempts});
        setRemainingAttempts(MAX_ATTEMPTS);
    }, [remainingAttempts, onRoundComplete]);
    const onMiss = useCallback(() => setRemainingAttempts(remainingAttempts - 1), [remainingAttempts]);

    useEffect(() => {
        if (remainingAttempts === 0) {
            onRoundComplete({score: 0});
        }
    }, [remainingAttempts, onRoundComplete]);

    return (
        <div className="password-guess-input">
            {expectedPassword.map((digit, index) => (
                <DigitGuessInput 
                    key={index}
                    digit={digit}
                    isFocused={index === roundIndex}
                    isVisible={index < roundIndex}
                    isMyTurn={isMyTurn}
                    onHit={onHit}
                    onMiss={onMiss}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

export const DigitGuessInput = ({isFocused, isVisible, isMyTurn, digit, onHit, onMiss, disabled}) => {
    const [value, setValue] = useState(null);
    const [showHint, setShowHint] = useState(false);

    const keyHandler = useCallback(e => {
        if (!isDigit(e.key) || showHint || disabled) {
            return;
        }

        const newValue = Number.parseInt(e.key);
        setValue(newValue);

        if (newValue !== digit) {
            setShowHint(true);
            onMiss();
        } else {
            onHit();
        }
    }, [digit, onHit, onMiss, showHint, disabled]);

    useLayoutEffect(() => {
        if (isFocused && isMyTurn) {
            window.addEventListener('keydown', keyHandler);
        }
        return () => window.removeEventListener('keydown', keyHandler);
    }, [keyHandler, isFocused, isMyTurn]);

    useLayoutEffect(() => {
        if (!showHint) {
            return;
        }

        window.setTimeout(() => setShowHint(false), 500);
    }, [showHint, value]);

    return (
        <div className={cx('digit-guess-input', {isFocused: isFocused && !disabled})}>
            {isVisible ? digit : (isFocused ? value : '*')}
            {isFocused && showHint && <div className="hint">{hint(value, digit)}</div>}
            {!isMyTurn && !isVisible && isFocused && <div className="hint">wait</div>}
        </div>
    );
}

export const ActivePlayersList = (props) => {
    const [players, setPlayers] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const values = Object.values(props.activePlayers);
        Promise.all(values.map(async (item) => {
            return {
                ...item,
                user: await describeUser(item.playerId),
            };
        }))
            .then(setPlayers)
            .catch(console.log)
            .finally(() => setLoading(false));
    }, [props.activePlayers]);

    if (isLoading) {
        return (
            <TextContent>Loading...</TextContent>
        );
    }

    return (
        <SpaceBetween size="xxs">
            {players.map((player, idx) => <div key={player.user.uid} className={cx('playerScore', {activeTurn: idx === props.roundIndex % players.length})}>
                <span className="playerName">{player.user.email.split('@')[0]}</span>
                <span className="scoreValue">{player.score}</span>
            </div>)}
        </SpaceBetween>
    )
}