import { initializeApp } from 'firebase/app';
import * as auth from 'firebase/auth';
import * as firestore from 'firebase/firestore';
import * as database from 'firebase/database';

const firebaseConfig = {
      apiKey: "AIzaSyBWPae_jx7AvJkaH_KmMxXs_I-8xWrowa8",
      authDomain: "guess-the-password-e0f31.firebaseapp.com",
      projectId: "guess-the-password-e0f31",
      storageBucket: "guess-the-password-e0f31.appspot.com",
      messagingSenderId: "584025823661",
      appId: "1:584025823661:web:1d3d429db4eebb4ce82924"
};

export const app = initializeApp(firebaseConfig);
export const defaultAuth = auth.getAuth(app);
export const db = firestore.getFirestore(app);
export const realtimeDatabase = database.getDatabase(app, 'https://guess-the-password-e0f31-default-rtdb.europe-west1.firebasedatabase.app');


export const USERS_COLLECTION = 'users';

export const signInWithEmailAndPassword = async (email, password) => {
      try {
            await auth.signInWithEmailAndPassword(defaultAuth, email, password);
      } catch (err) {
            console.error(err);
            alert(err.message);
      }
};

export const registerWithEmailAndPassword = async (email, password) => {
      try {
            const res = await auth.createUserWithEmailAndPassword(defaultAuth, email, password);
            const user = res.user;
            const ref = firestore.doc(db, USERS_COLLECTION, user.uid);
            await firestore.setDoc(ref, {
                  uid: user.uid,
                  authProvider: "local",
                  email,
            });
      } catch (err) {
            console.error(err);
      }
};

export const describeUser = async (uid) => {
      const rooms = firestore.collection(db, 'users');
      const q = firestore.query(rooms, firestore.where('uid', '==', uid));
      const snapshot = await firestore.getDocs(q);
      return snapshot.docs.map(doc => doc.data())[0];
}

export const logout = async () => {
      await auth.signOut(defaultAuth)
};
