import { AppLayout, Box, Button, Header, SideNavigation, SpaceBetween, Table } from "@awsui/components-react";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router";
import { defaultAuth } from "./firebase";
import { Link } from 'react-router-dom';

import './lobby.css';
import { listRooms } from "./rooms_api";

export function Lobby() {
    const [user, authLoading] = useAuthState(defaultAuth);
    const navigateTo = useNavigate();

    if (authLoading) {
        return (
            <div className="loadingUi">
                Loading lobby...
            </div>
        );
    }

    if (!user) {
        navigateTo('/');
    }

    return (
        <AppLayout
            headerSelector="#topNavigation"
            content={<RoomsList/>}
            contentHeader={<Header variant="h1">Game Rooms</Header>}
            maxContentWidth={600}
            navigation={<LobbyNavigation/>}
            toolsHide
        />
    );
}

export function RoomsList() {
    const [isLoading, setLoading] = useState(true);
    const [rooms, setRooms] = useState([]);
    const navigateTo = useNavigate();

    useEffect(() => {
        listRooms()
            .then(setRooms)
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }, []);

    const columns = [
        {
            id: 'roomName',
            header: 'Room Name',
            cell: ([id, room]) => <Link to={`/rooms/${id}`}>{room.roomName}</Link>
        },
    ];

    return (
        <Table
            isLoading={isLoading}
            columnDefinitions={columns}
            items={rooms}
            empty={<Box textAlign="center" color="inherit">
                <SpaceBetween size="xxxxs">
                    <strong>No items</strong>
                    <p>There are no available game rooms.</p>
                    <Button onClick={() => navigateTo('/rooms/create')}>Create Game Room</Button>
                </SpaceBetween>
            </Box>}
        />
    );
}

export const LobbyNavigation = () => {
    const navigateTo = useNavigate();
    const {pathname} = useLocation();

    return (
        <SideNavigation
            header={{
                href: '/lobby',
                text: 'Lobby',
            }}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    navigateTo(event.detail.href);
                }
            }}
            activeHref={pathname}
            items={[
                {type: 'link', text: 'Create Room', href: `/rooms/create`},
                {type: 'link', text: 'Game Rooms', href: `/lobby`},
            ]}
        />
    );
}