import { AppLayout, Box, Button, Header, SpaceBetween, Table } from "@awsui/components-react";
import { useNavigate, useParams } from "react-router"
import { useEffect, useState } from "react";
import { describeUser } from "./firebase";
import { useRoom } from "./rooms_api";
import { LobbyNavigation } from "./lobby";

export const GameStats = () => {
    const { roomId } = useParams();
    const [ room, roomMeta, isRoomLoading ] = useRoom(roomId);
    const [ statsLoading, setStatsLoading ] = useState(true);
    const [ stats, setStats ] = useState([]);

    const navigateTo = useNavigate();

    useEffect(() => {
        if (isRoomLoading) {
            return;
        }

        const values = Object.values(roomMeta.players);
        Promise.all(values.map(async (item) => ({
            ...item,
            user: await describeUser(item.playerId),
        })))
            .then(setStats)
            .catch(console.log)
            .finally(() => setStatsLoading(false));
    }, [isRoomLoading, roomMeta]);

    const columns = [
        {
            id: 'playerName',
            header: 'Player',
            cell: item => item.user.email,
        },
        {
            id: 'finalScore',
            header: 'Score',
            cell: item => item.score,
        },
    ];

    const contentHeader = (
        <Header variant="h1" description={<>Room ID: <strong>{roomId}</strong></>}>{room ? room.roomName : 'Game Stats'}</Header>
    );
    const content = (
        <SpaceBetween size="s">
            <Table
                header={<Header>Game Stats</Header>}
                columnDefinitions={columns}
                loading={statsLoading}
                items={stats}
            />
            <Box float="right">
                <SpaceBetween size="xs" direction="horizontal">
                    <Button onClick={() => navigateTo('/lobby')}>Go to Lobby</Button>
                </SpaceBetween>
            </Box>
        </SpaceBetween>
    );

    return (
        <AppLayout
            headerSelector="#topNavigation"
            content={content}
            contentHeader={contentHeader}
            maxContentWidth={800}
            navigation={<LobbyNavigation />}
            toolsHide
        />
    );

}