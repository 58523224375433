import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from './login';
import { Register } from './register';
import { StrictMode } from "react";
import { Lobby } from './lobby';
import { GameRoom } from './room';
import { useNavigate } from "react-router";

import "@awsui/global-styles/index.css";
import TopNavigation from "@awsui/components-react/top-navigation/1.0-beta";
import { GameStats } from "./game-stats";
import { CreateRoom } from "./create-room";

const Navigation = () => {
  const navigateTo = useNavigate();
  
  return (
    <TopNavigation
      id="topNavigation"
      identity={{
        title: 'GuessThePassword',
        href: '/',
        onFollow: (event) => {
          event.preventDefault();
          navigateTo(event.detail.href);
        }
      }}
      i18nStrings={{
        overflowMenuTriggerText: 'overflow',
      }}
  />
  )
};

function App() {
  return (
    <StrictMode>
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/lobby" element={<Lobby />} />
          <Route exact path="/rooms/create" element={<CreateRoom />} />
          <Route exact path="/rooms/:roomId" element={<GameRoom />} />
          <Route exact path="/rooms/:roomId/stats" element={<GameStats />} />
        </Routes>
      </Router>
    </StrictMode>
  )
}

export default App;
