export const generatePassword = (length) => {
    const password = [];
    for (let i = 0; i < length; i++) {
        password.push(Math.floor(Math.random() * 10));
    }
    return password;
}

export const toDigits = (password) => {
    const digits = [];
    while (password) {
        digits.push(password % 10);
        password = Math.floor(password / 10);
    }

    return digits.reverse();
}

export const isDigit = (key) => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].find(e => e.toString() === key) !== undefined;
}

export const hint = (val, expected) => {
    if (val < expected) {
        return 'higher';
    }
    if (val > expected) {
        return 'lower';
    }

    throw new Error('Shouldn\'t call hint() when the value is as expected.');
};
