import { AppLayout, Button, Container, Form, FormField, Header, Input, SpaceBetween, Toggle } from "@awsui/components-react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { defaultAuth } from "./firebase";
import { LobbyNavigation } from './lobby';
import { createRoom } from "./rooms_api";


export const CreateRoom = () => {
    const [user, authLoading] = useAuthState(defaultAuth);
    const navigateTo = useNavigate();

    if (authLoading) {
        return (
            <div className="loadingUi">
                Loading lobby...
            </div>
        );
    }

    if (!user) {
        navigateTo('/');
    }

    const content = (
        <RoomCreator onCreateSuccess={roomId => navigateTo(`/rooms/${roomId}`)} />
    );

    return (
        <AppLayout
            toolsHide
            headerSelector="#topNavigation"
            content={content}
            maxContentWidth={600}
            navigation={<LobbyNavigation/>}
        />
    );
};

export const RoomCreator = (props) => {
    const [roomName, setRoomName] = useState('');
    const [passwordLength, setPasswordLength] = useState('6');
    const [isPublic, setPublic] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const startRoomCreation = () => {
        setLoading(true);
        createRoom(roomName, Number.parseInt(passwordLength), isPublic)
            .then(props.onCreateSuccess)
            .catch(err => console.log(err));
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="primary" onClick={startRoomCreation} loading={isLoading}>
                        Create
                    </Button>
                </SpaceBetween>
            }
            header={
                <Header variant="h1" description="Create a new GuessThePassword game room">
                    Create new room
                </Header>
            }
        >
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Room Name" description="The name of the room as will be visible by others">
                        <Input value={roomName} onChange={event => setRoomName(event.detail.value)} />
                    </FormField>
                    <FormField label="Password Length" description="The length of the password to be guessed in this game.">
                        <Input value={passwordLength} onChange={event => setPasswordLength(event.detail.value)} inputMode="numeric" type="number" />
                    </FormField>
                    <FormField label="Public" description="Makes the room visible to anyone who wants to join">
                        <Toggle checked={isPublic} onChange={event => setPublic(event.detail.checked)} />
                    </FormField>
                </SpaceBetween>
            </Container>
        </Form>
    )
};
